<template>
  <div>
    <DeleteAccountListCard :search="search" :deletionAccounts="{}" />
  </div>
</template>
<script>
import DeleteAccountListCard from "../components/DeleteAccountListCard";
export default {
  components: {
    DeleteAccountListCard,
  },
  data() {
    return {
      dialog: false,
      scores: [
        { value: "Tümü", key: -1 },
        { value: 1, key: 1 },
        { value: 2, key: 2 },
        { value: 3, key: 3 },
        { value: 4, key: 4 },
        { value: 5, key: 5 },
      ],
      currentPage: 1,
      search: "",
    };
  },
};
</script>
