<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>deletionAccount</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      hide-default-footer
      :options.sync="options"
      :items="deletionAccounts.data"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.deleteAccount.deletionRequestTime="{ item }">
        <div>{{ new Date(item.deleteAccount.deletionRequestTime * 1000) | formatDate }}</div>
      </template>
            <template v-slot:item.deleteAccount.deletionTime="{ item }">
        <div>{{ new Date(item.deleteAccount.deletionTime * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.id="{ item }">
        <UserDetailCard
          :userId="item.id"
          :pictureUrl="item.pictureUrl"
          :userName="item.userName"
        />
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="deletionAccounts.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  name: "DeleteAccountListCard",
  props: {
    deletionAccounts: {
      type: Object,
      description: "deletionAccount",
    },
    search: {
      type: String,
      description: "search",
    },
  },
  data() {
    return {
      dialog: false,
      options: {},
      scores: [
        { value: "Tümü", key: -1 },
        { value: 1, key: 1 },
        { value: 2, key: 2 },
        { value: 3, key: 3 },
        { value: 4, key: 4 },
        { value: 5, key: 5 },
      ],
      currentPage: 1,
      headers: [
        { text: "User", value: "id" },
        { text: "Message", value: "deleteAccount.message" },
        { text: "type", value: "deleteAccount.type" },
        { text: "isDelete", value: "deleteAccount.isDeleted" },
        { text: "Created Date", value: "deleteAccount.deletionRequestTime" },
        { text: "DeletionTime Date", value: "deleteAccount.deletionTime" },

        
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.deletionAccounts = await this.$store.dispatch("deletionAccount/getAll", {
        page: this.currentPage - 1,
        search: this.search,
        options: this.options,
      });
    },
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>
